jQuery(document).ready(function ($) {

    /*$('.mask-nip').inputmask({
     regex: "((\\d{9})|\\d{12})|([A-Za-z]{2}\\d{3})|(\\d{3}-\\d{3}-\\d{2}-\\d{2})"
     });*/

    /*$('.mask-postcode').inputmask({
     regex: "([a-zA-z]\\d[ ]\\d[a-zA-z]{2})|([a-zA-z]\\d{2}[ ]\\d[a-zA-z]{2})|([a-zA-z]\\d[a-zA-z][ ]\\d[a-zA-z]{2})|([a-zA-z]{2}\\d[ ]\\d[a-zA-z]{2})|([a-zA-z]{2}\\d{2}[ ]\\d[a-zA-z]{2})|([a-zA-z]{2}\\d[a-zA-z][ ]\\d[a-zA-z]{2})",
     });*/

    $('.mask-phone').inputmask({
        regex: "[\\d +-]*",
    });

    $('.mask-email').inputmask({
        alias: "email",
    });

    $('.mask-number').inputmask({
        regex: "\\d*",
    });

    $('.mask-date').inputmask({
        alias: 'datetime',
        inputFormat: 'dd.mm.yyyy',
        placeholder: 'DD.MM.RRRR',
    });

    $('.mask-time').inputmask({
        alias: 'datetime',
        inputFormat: 'HH:MM',
        placeholder: 'GG:MM',
    });

});
