jQuery(document).ready(function ($) {

    $('.home-slider').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="icon-arrow-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="icon-arrow-right"></i></button>',
        fade: true,
        asNavFor: '.home-slider--nav__items',
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    appendArrows: '.home-slider__arrows',
                }
            },
        ],
    });

    $('.home-slider--nav__items').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: false,
        fade: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
        focusOnSelect: true,
        asNavFor: '.home-slider',
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                }
            },
        ],
    });

    $('.product__slider--main').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: false,
        fade: true,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    appendArrows: '.product__gallery__arrows',
                    prevArrow: '<button type="button" class="slick-prev"><i class="icon-arrow-left"></i></button>',
                    nextArrow: '<button type="button" class="slick-next"><i class="icon-arrow-right"></i></button>',
                    fade: false,
                }
            },
        ],
    });

    $('.product__slider--nav').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: false,
        fade: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
        focusOnSelect: true,
        asNavFor: '.product__slider--main',
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                }
            }
        ],
    });

    $('.product__upsells .products').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: true,
        appendArrows: '.upsells__arrows',
        prevArrow: '<button type="button" class="slick-prev"><i class="icon-arrow-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="icon-arrow-right"></i></button>',
        fade: false,
        slidesToShow: 5,
        slidesToScroll: 5,
        centerMode: false,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ],
    });

    $('.home-products__slider').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: true,
        autoplayspeed: 3000,
        cssEase: 'ease-out',
        arrows: true,
        appendArrows: '.home-products__slider-arrows',
        prevArrow: '<button type="button" class="slick-prev"><i class="icon-arrow-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="icon-arrow-right"></i></button>',
        fade: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: false,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                }
            }
        ],
    });

});
