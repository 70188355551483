jQuery(document).ready(function ($) {
    setInterval(function time() {
        var d = new Date();
        var sh = $('.sendtimer').attr('sendhour');

        if (sh) {
            if (sh == 0) {
                sh = 24;
            }

            var sm = $('.sendtimer').attr('sendminute');
            if (sm == 0 || !sm) {
                sm = 60;
            }

            var h = (sh - 1) - d.getHours();
            var m = (sm - 1) - d.getMinutes();
            var s = 59 - d.getSeconds();

            if (sh == d.getHours()) {
                h = h + 1;
            }

            if (d.getHours() >= sh && (d.getMinutes() >= sm || sm === 60)) {
                if (sh == d.getHours()) {
                    h = 23 + h;
                } else {
                    h = 24 + h;
                }

                if (!(sm === 60)) {
                    m = 60 + m;
                }
            }

            if (d.getDay() === 5 && ((d.getHours() === sh && (d.getMinutes() >= sm || sm === 60)) || d.getHours() > sh)) {
                h = h + 48;
            } else if (d.getDay() === 6) {
                h = h + 24;
            }

            if ((h + '').length === 1) {
                h = '0' + h;
            }
            if ((m + '').length === 1) {
                m = '0' + m;
            }
            if ((s + '').length === 1) {
                s = '0' + s;
            }

            $('.sendtimer .sendtimer__timer .sendtimer__timer-content').html(h + ':' + m + ':' + s);
        }
    }, 1000);

});
